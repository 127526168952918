import React, { useState } from 'react'
import {
  Box,
  FormControl,
  FormLabel,
  Button,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SimpleGrid,
} from '@chakra-ui/react'

import securedApi from 'backend/axios'
import { processApiError } from 'helpers/utils'

const WidgetSliders = ({ sliders, onSetSliders, scheduledActivityId, readOnly }) => {
  const sliderNames = sliders.map((x) => x.name)

  const initialSliderValues = sliders.map((x) => ({ name: x.name, value: x.value }))
  const [sliderValues, setSliderValues] = useState(initialSliderValues)

  const setSliderValue = (name, value) => {
    setSliderValues((prevSliderValues) =>
      prevSliderValues.map((obj) => (obj.name === name ? { ...obj, value: value } : obj))
    )
  }

  const handleClearSlider = (name) => {
    setSliderValue(name, null)
    handleChangeSlider(name, null)
  }

  const handleChangeSlider = (name, value) => {
    const payload = { value: value }
    const newState = sliders
    const slider = sliders.find((x) => x.name === name)
    const sliderIndex = sliderNames.indexOf(name)

    // update state value so complete submit has correct value
    newState[sliderIndex].value = value
    onSetSliders(newState)

    // update entry or create one if none exists yet
    securedApi
      .put(`api/v1/widget_slider_entries/control`, {
        id: slider.id,
        widget_slider_entry: {
          scheduled_activity_id: scheduledActivityId,
          widget_slider_id: slider.widget_slider_id,
          value: value,
        },
      })
      .catch((error) => processApiError(error))
  }

  // the Slider itself doesn't work properly in its own functional component
  // because the onChange HAS to be a setState (e.g. setMotivated) otherwise it doesn't work
  // (putting it in a wrapper function wouldn't work)
  // so we create functional components for the things around it...
  // the commonSliderSettings doesn't make it as disastrous as it seemed

  const commonSliderSettings = {
    min: 1,
    max: 10,
    step: 1,
    colorScheme: 'yellow',
    focusThumbOnChange: false,
  }

  const ActivitySliderLabelClear = ({ name, value }) => {
    const mapLabel = {
      RPE: 'RPE',
      felt: 'Feeling',
      motivated: 'Motivation',
      performed: 'Performance',
    }

    const label = mapLabel[name] || name

    const ClearButton = () =>
      value && (
        <Button
          ml="2"
          size="xs"
          variant="link"
          colorScheme="yellow"
          onClick={() => handleClearSlider(name)}
        >
          Clear
        </Button>
      )

    return (
      <FormControl>
        <FormLabel fontSize="sm">
          {label}
          {!readOnly && <ClearButton />}
          <Box fontSize="xs" color="gray">
            (1 = Low – 10 = High)
          </Box>
        </FormLabel>
      </FormControl>
    )
  }

  const ActivitySliderTrackThumb = ({ value }) => {
    return (
      <>
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb boxSize={6} borderWidth="1" borderColor="yellow.500">
          <Box color="yellow.500">{value}</Box>
        </SliderThumb>
      </>
    )
  }

  const SliderGroup = ({ name, value }) => {
    // if only 1 slider don't make it 100% width of the modal
    const width = sliders.length > 1 ? '100%' : '50%'

    return (
      <Box width={width}>
        <ActivitySliderLabelClear name={name} value={value} />
        <Slider
          id={`slider_${name}`}
          defaultValue={value}
          onChange={(newValue) => {
            setSliderValue(name, newValue)
            handleChangeSlider(name, newValue)
          }}
          isReadOnly={readOnly}
          {...commonSliderSettings}
        >
          <ActivitySliderTrackThumb value={value} />
        </Slider>
      </Box>
    )
  }

  if (!sliders.length) {
    return null
  }

  // if more than 3 sliders then put in 2 columns
  const gridColumns = sliders.length > 3 ? 2 : sliders.length

  return (
    <Box my={4}>
      <SimpleGrid columns={gridColumns} spacing={2}>
        {sliders.map((slider) => {
          // use state variable for the value rather than the slider object because it tracks user updates
          const sliderValue = sliderValues.find((x) => x.name === slider.name)

          return <SliderGroup key={slider.id} name={sliderValue.name} value={sliderValue.value} />
        })}
      </SimpleGrid>
    </Box>
  )
}

export default WidgetSliders
