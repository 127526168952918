import React, { useEffect, useState } from 'react'
import { Edit } from 'react-feather'
import { useForm, Controller, FormProvider } from 'react-hook-form'
import {
  ButtonGroup,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Icon,
  Text,
  Tooltip,
  Spacer,
  Stack,
} from '@chakra-ui/react'

import securedApi from 'backend/axios'

import Notify from 'components/Notification'
import LoadingSpinner from 'components/LoadingSpinner'
import RichTextArea from 'components/RichTextArea'

import { processGetError, processApiError } from 'helpers/utils'

import { dayMapper } from '../helpers'

const TemplateNote = ({ open, onClose, noteId, getAndSetNewDayData }) => {
  const [loading, setLoading] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [noteData, setNoteData] = useState({})
  const [isEditing, setIsEditing] = useState(false)

  const methods = useForm()
  const { control, register, handleSubmit } = methods

  useEffect(() => {
    if (open && noteId) {
      setLoading(true)
      securedApi
        .get(`api/v1/templated_scheduled_notes/${noteId}`)
        .then(({ data }) => setNoteData(data))
        .catch((error) => processGetError(error))
        .finally(() => setLoading(false))
    }
  }, [open, noteId])

  const onSubmit = ({ title, details }) => {
    setProcessing(true)

    securedApi
      .patch(`api/v1/templated_scheduled_notes/${noteData.id}`, {
        title,
        description: details,
      })
      .then(() => {
        getAndSetNewDayData()
        Notify({ content: 'Updated', type: 'success' })
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setProcessing(false)
        onClose()
      })
  }

  const setEditMode = () => {
    setIsEditing(!isEditing)
  }

  const handleClose = () => {
    setIsEditing(false)
    onClose()
  }

  const deleteNote = () => {
    securedApi
      .delete(`api/v1/templated_scheduled_notes/${noteId}`)
      .then(() => {
        Notify({
          content: 'Note deleted',
          type: 'success',
        })
        getAndSetNewDayData()
        handleClose()
      })
      .catch((error) => processApiError(error))
  }

  const renderNote = () => {
    if (isEditing) {
      return (
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader pt="0" pb="0" display="flex" alignItems="center" mt="6">
              <FormControl isRequired>
                <FormLabel fontSize="sm" mb="0">
                  Title
                </FormLabel>
                <Input
                  defaultValue={noteData.title}
                  placeholder="Awesome training day"
                  {...register('title')}
                />
              </FormControl>
            </ModalHeader>

            <ModalBody>
              <Stack spacing="4">
                <FormControl>
                  <FormLabel fontSize="sm" mb="0">
                    Details
                  </FormLabel>
                  <Controller
                    name="details"
                    defaultValue={noteData.description}
                    control={control}
                    render={({ field }) => <RichTextArea {...field} />}
                  />
                </FormControl>
              </Stack>
            </ModalBody>

            <ModalFooter>
              <ButtonGroup spacing="4" marginTop="2" display="flex" width="100%">
                <Button variant="outline" onClick={deleteNote} disabled={processing}>
                  Delete
                </Button>
                <Spacer />
                <Button variant="outline" onClick={setEditMode} disabled={processing}>
                  Cancel
                </Button>
                <Button type="submit" colorScheme="brand" isLoading={processing}>
                  Update
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </form>
        </FormProvider>
      )
    }

    return (
      <>
        <ModalHeader pb="0" display="flex" alignItems="center">
          {noteData.title}
          <Spacer />
          <Tooltip label="Edit">
            <Icon
              as={Edit}
              w="4"
              h="4"
              color="gray.500"
              mt="-3"
              mr="6"
              _hover={{ cursor: 'pointer', color: 'brand.500' }}
              onClick={setEditMode}
            />
          </Tooltip>
        </ModalHeader>

        <ModalBody pb="4">
          <Text fontSize="xs" pb="2">
            {dayMapper[noteData.day]} - Week {noteData.week}
          </Text>
          <Text
            dangerouslySetInnerHTML={{
              __html: noteData.description,
            }}
          />
        </ModalBody>
      </>
    )
  }

  if (!Object.keys(noteData).length) {
    return null
  }

  if (loading) {
    return <LoadingSpinner />
  }

  return (
    <>
      <Modal
        isOpen={open}
        onClose={handleClose}
        size="3xl"
        closeOnOverlayClick={isEditing ? false : true}
      >
        <ModalOverlay />
        <ModalContent pb="2">
          <ModalCloseButton />
          {renderNote()}
        </ModalContent>
      </Modal>
    </>
  )
}

export default TemplateNote
