import React, { useEffect, useContext } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

import { useUserPreferences } from 'hooks'

import NotificationContainer from 'components/Notification/NotificationContainer'

import InAppHelp from 'components/General/InAppHelp'

import Dashboard from './Dashboard'
import ExpiredOverlay from './ExpiredOverlay'

import { Planner } from './Planner'

import Settings from './Settings'

import Metrics from './Metrics/Metrics'
import MetricsCustomMeasures from './Metrics/MetricsCustomMeasures'
import LogbookMetricsWrapper from './Metrics/Widgets/Logbook/LogbookMetricsWrapper'
import SelectMetricsWrapper from './Metrics/Widgets/Select/SelectMetricsWrapper'
import DailyMetrics from './Metrics/DailyMetrics'
import IncompleteReasons from './Metrics/IncompleteReasons'
import MetricsZoom from './Metrics/MetricsZoom'
import DailyMetricsSettings from './DailyMetrics/DailyMetricsSettings'
import ImportDailyMetrics from './DailyMetrics/ImportDailyMetrics'

import SubscriptionCreate from './Subscription/SubscriptionCreate'
import SubscriptionSuccessful from './Subscription/SubscriptionSuccessful'
import SubscriptionCancelled from './Subscription/SubscriptionCancelled'

import SignIn from './Auth/SignIn'
import SignUp from './Auth/SignUp'
import SignUp9D from './Auth/SignUp9D'
import SignUpCoachingClient from './Auth/SignUpCoachingClient'
import ForgotPassword from './Auth/ForgotPassword'
import ResetPassword from './Auth/ResetPassword'
import UnsubscribeWeekly from './Auth/UnsubscribeWeekly'
import UnsubscribeDaily from './Auth/UnsubscribeDaily'

import Welcome from './Onboarding/Welcome'
import WelcomePlan from './Onboarding/WelcomePlan'
import WelcomePlanApply from './Onboarding/WelcomePlanApply'

import Categories from './Categories'

import Workout from './Workouts/Workout'
import Workouts from './Workouts/Workouts'
import WorkoutEdit from './Workouts/WorkoutEdit'
import WorkoutCreate from './Workouts/WorkoutCreate'

import Widgets from './Widgets/Widgets'

import Plans from './Plans'
import PlanCollections from './Plans/PlanCollections'
import Plan from './Plans/Plan'
import PurchasedPlans from './Plans/PurchasedPlans'

import Purchases from './Purchases/Purchases'
import PurchaseLanding from './Purchases/PurchaseLanding'
import PurchaseLandingExternal from './Purchases/PurchaseLandingExternal'

import Goals from './Goals'

import Tests from './Tests'

import Milestones from './Milestones/Milestones'
import Milestone from './Milestones/Milestone'
import MilestoneCreate from './Milestones/MilestoneCreate'
import MilestoneEdit from './Milestones/MilestoneEdit'

import Notes from './Notes/Notes'
import NoteCreate from './Notes/NoteCreate'
import Note from './Notes/Note'
import NoteEdit from './Notes/NoteEdit'

import NoMatch from './NoMatch'

import CoachingDashboard from './Coaching/CoachingDashboard'
import CoachingClients from './Coaching/CoachingClients'
import CoachingClient from './Coaching/CoachingClient'
import CoachingWorkouts from './Coaching/CoachingWorkouts'
import CoachingWorkout from './Coaching/CoachingWorkout'
import CreateCoachingWorkout from './Coaching/CreateCoachingWorkout'
import CoachingWorkoutEdit from './Coaching/CoachingWorkoutEdit'
import BulkCoachingWorkouts from './Coaching/BulkCoachingWorkouts'
import CoachingBilling from './Coaching/CoachingBilling'
import CoachingTemplates from './Coaching/Templates'
import CoachingTemplate from './Coaching/Templates/Template'

import { checkAuth, PrivateRouteWrapper, PublicRouteWrapper } from './Auth/Helpers'

import { UserContext } from 'providers/UserProvider'

const App = () => {
  const { validContext } = useContext(UserContext)
  const [userValid] = validContext

  // must explicitly test for false (to ignore undefined and null)
  const showExpiredOverlay = userValid === false

  const getUserPreferences = useUserPreferences((state) => state.fetch)
  useEffect(() => {
    // don't fetch preferences if user is not valid (e.g. signed out)
    if (userValid) {
      getUserPreferences()
    }
  }, [getUserPreferences, userValid])

  return (
    <>
      <Routes>
        <Route
          exact
          path="/"
          element={checkAuth() ? <Navigate to="/dashboard" /> : <Navigate to="/sign-in" />}
        />

        <Route
          path="/sign-in"
          element={
            <PublicRouteWrapper>
              <SignIn />
            </PublicRouteWrapper>
          }
        />
        <Route
          path="/sign-up"
          element={
            <PublicRouteWrapper>
              <SignUp />
            </PublicRouteWrapper>
          }
        />
        <Route
          exact
          path="/sign-up-9d"
          element={
            <PublicRouteWrapper>
              <SignUp9D />
            </PublicRouteWrapper>
          }
        />

        <Route
          exact
          path="/sign-up-client"
          element={
            <PublicRouteWrapper>
              <SignUpCoachingClient />
            </PublicRouteWrapper>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <PublicRouteWrapper>
              <ForgotPassword />
            </PublicRouteWrapper>
          }
        />

        <Route
          exact
          path="/settings/reset-password"
          element={
            <PublicRouteWrapper>
              <ResetPassword />
            </PublicRouteWrapper>
          }
        />

        <Route
          exact
          path="/settings/unsubscribe_weekly"
          element={
            <PublicRouteWrapper>
              <UnsubscribeWeekly />
            </PublicRouteWrapper>
          }
        />

        <Route
          exact
          path="/settings/unsubscribe_daily"
          element={
            <PublicRouteWrapper>
              <UnsubscribeDaily />
            </PublicRouteWrapper>
          }
        />

        <Route
          exact
          path="/welcome"
          element={
            <PrivateRouteWrapper hideNavigation>
              <Welcome />
            </PrivateRouteWrapper>
          }
        />

        <Route
          exact
          path="/welcome-plan"
          element={
            <PrivateRouteWrapper hideNavigation>
              <WelcomePlan />
            </PrivateRouteWrapper>
          }
        />

        <Route
          exact
          path="/welcome-plan-apply"
          element={
            <PrivateRouteWrapper hideNavigation>
              <WelcomePlanApply />
            </PrivateRouteWrapper>
          }
        />

        <Route
          exact
          path="/welcome-client"
          element={
            <PrivateRouteWrapper hideNavigation>
              <Welcome />
            </PrivateRouteWrapper>
          }
        />

        <Route
          path="/settings/*"
          element={
            <PrivateRouteWrapper>
              <Settings />
            </PrivateRouteWrapper>
          }
        />

        <Route
          exact
          path="/subscription-create"
          element={
            <PrivateRouteWrapper>
              <SubscriptionCreate />
            </PrivateRouteWrapper>
          }
        />

        <Route
          exact
          path="/subscription-successful"
          element={
            <PrivateRouteWrapper>
              <SubscriptionSuccessful />
            </PrivateRouteWrapper>
          }
        />

        <Route
          exact
          path="/subscription-cancelled"
          element={
            <PrivateRouteWrapper>
              <SubscriptionCancelled />
            </PrivateRouteWrapper>
          }
        />

        <Route
          exact
          path="/dashboard"
          element={
            <PrivateRouteWrapper>
              <Dashboard />
            </PrivateRouteWrapper>
          }
        />

        <Route
          exact
          path="/planner"
          element={
            <PrivateRouteWrapper>
              <Planner />
            </PrivateRouteWrapper>
          }
        />

        <Route
          exact
          path="/metrics"
          element={
            <PrivateRouteWrapper>
              <Metrics />
            </PrivateRouteWrapper>
          }
        />

        <Route
          exact
          path="/metrics/custom-measures"
          element={
            <PrivateRouteWrapper>
              <MetricsCustomMeasures />
            </PrivateRouteWrapper>
          }
        />

        <Route
          exact
          path="/metrics/custom-selects"
          element={
            <PrivateRouteWrapper>
              <SelectMetricsWrapper />
            </PrivateRouteWrapper>
          }
        />

        <Route
          exact
          path="/metrics/logbooks"
          element={
            <PrivateRouteWrapper>
              <LogbookMetricsWrapper />
            </PrivateRouteWrapper>
          }
        />

        <Route
          exact
          path="/metrics/incomplete-reasons"
          element={
            <PrivateRouteWrapper>
              <IncompleteReasons />
            </PrivateRouteWrapper>
          }
        />

        <Route
          exact
          path="/metrics/zoom"
          element={
            <PrivateRouteWrapper>
              <MetricsZoom />
            </PrivateRouteWrapper>
          }
        />

        <Route
          exact
          path="/metrics/daily-metrics"
          element={
            <PrivateRouteWrapper>
              <DailyMetrics />
            </PrivateRouteWrapper>
          }
        />

        <Route
          exact
          path="/metrics/daily-metrics-settings"
          element={
            <PrivateRouteWrapper>
              <DailyMetricsSettings />
            </PrivateRouteWrapper>
          }
        />

        <Route
          exact
          path="/metrics/biometrics-import"
          element={
            <PrivateRouteWrapper>
              <ImportDailyMetrics />
            </PrivateRouteWrapper>
          }
        />

        <Route
          exact
          path="/categories"
          element={
            <PrivateRouteWrapper>
              <Categories />
            </PrivateRouteWrapper>
          }
        />

        <Route
          exact
          path="/workouts"
          element={
            <PrivateRouteWrapper>
              <Workouts />
            </PrivateRouteWrapper>
          }
        />

        <Route
          exact
          path="/workout/:id"
          element={
            <PrivateRouteWrapper>
              <Workout />
            </PrivateRouteWrapper>
          }
        />

        <Route
          exact
          path="/workout/:id/edit"
          element={
            <PrivateRouteWrapper>
              <WorkoutEdit />
            </PrivateRouteWrapper>
          }
        />

        <Route
          path="/create-workout"
          element={
            <PrivateRouteWrapper>
              <WorkoutCreate />
            </PrivateRouteWrapper>
          }
        />

        <Route
          path="/widgets"
          element={
            <PrivateRouteWrapper>
              <Widgets />
            </PrivateRouteWrapper>
          }
        />

        <Route
          exact
          path="/plans"
          element={
            <PrivateRouteWrapper>
              <Plans />
            </PrivateRouteWrapper>
          }
        />

        <Route
          exact
          path="/plans/:id"
          element={
            <PrivateRouteWrapper>
              <PlanCollections />
            </PrivateRouteWrapper>
          }
        />

        <Route
          exact
          path="/plans/plan/:id"
          element={
            <PrivateRouteWrapper>
              <Plan />
            </PrivateRouteWrapper>
          }
        />

        <Route
          exact
          path="/plans/purchased-plans"
          element={
            <PrivateRouteWrapper>
              <PurchasedPlans />
            </PrivateRouteWrapper>
          }
        />

        <Route
          exact
          path="/purchases"
          element={
            <PrivateRouteWrapper>
              <Purchases />
            </PrivateRouteWrapper>
          }
        />

        <Route
          exact
          path="/purchase_landing/:id"
          element={
            <PrivateRouteWrapper>
              <PurchaseLanding />
            </PrivateRouteWrapper>
          }
        />

        <Route exact path="/purchase_landing_external/:id" element={<PurchaseLandingExternal />} />

        <Route
          exact
          path="/goals/*"
          element={
            <PrivateRouteWrapper>
              <Goals />
            </PrivateRouteWrapper>
          }
        />

        <Route
          exact
          path="/tests/*"
          element={
            <PrivateRouteWrapper>
              <Tests />
            </PrivateRouteWrapper>
          }
        />

        <Route
          exact
          path="/notes"
          element={
            <PrivateRouteWrapper>
              <Notes />
            </PrivateRouteWrapper>
          }
        />

        <Route
          exact
          path="/note/create"
          element={
            <PrivateRouteWrapper>
              <NoteCreate />
            </PrivateRouteWrapper>
          }
        />

        <Route
          exact
          path="/note/:id"
          element={
            <PrivateRouteWrapper>
              <Note />
            </PrivateRouteWrapper>
          }
        />

        <Route
          exact
          path="/note/edit/:id"
          element={
            <PrivateRouteWrapper>
              <NoteEdit />
            </PrivateRouteWrapper>
          }
        />

        <Route
          exact
          path="/milestones"
          element={
            <PrivateRouteWrapper>
              <Milestones />
            </PrivateRouteWrapper>
          }
        />

        <Route
          exact
          path="/milestone/create"
          element={
            <PrivateRouteWrapper>
              <MilestoneCreate />
            </PrivateRouteWrapper>
          }
        />

        <Route
          exact
          path="/milestone/:id"
          element={
            <PrivateRouteWrapper>
              <Milestone />
            </PrivateRouteWrapper>
          }
        />

        <Route
          exact
          path="/milestone/edit/:id"
          element={
            <PrivateRouteWrapper>
              <MilestoneEdit />
            </PrivateRouteWrapper>
          }
        />

        <Route
          path="/coaching"
          element={
            <PrivateRouteWrapper>
              <CoachingDashboard />
            </PrivateRouteWrapper>
          }
        />

        <Route
          path="/coaching/clients"
          element={
            <PrivateRouteWrapper>
              <CoachingClients />
            </PrivateRouteWrapper>
          }
        />

        <Route
          path="/coaching/client/:id"
          element={
            <PrivateRouteWrapper>
              <CoachingClient />
            </PrivateRouteWrapper>
          }
        />

        <Route
          exact
          path="/coaching/workouts"
          element={
            <PrivateRouteWrapper>
              <CoachingWorkouts />
            </PrivateRouteWrapper>
          }
        />

        <Route
          exact
          path="/coaching-workout/:id"
          element={
            <PrivateRouteWrapper>
              <CoachingWorkout />
            </PrivateRouteWrapper>
          }
        />

        <Route
          exact
          path="/coaching-workout/:id/edit"
          element={
            <PrivateRouteWrapper>
              <CoachingWorkoutEdit />
            </PrivateRouteWrapper>
          }
        />

        <Route
          path="/create-coaching-workout"
          element={
            <PrivateRouteWrapper>
              <CreateCoachingWorkout />
            </PrivateRouteWrapper>
          }
        />

        <Route
          path="/bulk-add-coaching-workouts"
          element={
            <PrivateRouteWrapper>
              <BulkCoachingWorkouts />
            </PrivateRouteWrapper>
          }
        />

        <Route
          exact
          path="/coaching/widgets"
          element={
            <PrivateRouteWrapper>
              <Widgets templatedActivity={true} />
            </PrivateRouteWrapper>
          }
        />

        <Route
          exact
          path="/coaching/templates"
          element={
            <PrivateRouteWrapper>
              <CoachingTemplates />
            </PrivateRouteWrapper>
          }
        />

        <Route
          exact
          path="/coaching/template/:id"
          element={
            <PrivateRouteWrapper>
              <CoachingTemplate />
            </PrivateRouteWrapper>
          }
        />

        <Route
          exact
          path="/coaching/billing"
          element={
            <PrivateRouteWrapper>
              <CoachingBilling />
            </PrivateRouteWrapper>
          }
        />

        <Route
          path="*"
          element={
            <PublicRouteWrapper>
              <NoMatch />
            </PublicRouteWrapper>
          }
        />
      </Routes>

      <NotificationContainer />

      <InAppHelp />

      {showExpiredOverlay && <ExpiredOverlay />}
    </>
  )
}

export default App
