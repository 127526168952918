import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  Box,
  Stack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Radio,
  RadioGroup,
} from '@chakra-ui/react'
import { add, endOfWeek, startOfWeek, startOfToday } from 'date-fns'

import securedApi from 'backend/axios'
import { processApiError, dateToString } from 'helpers/utils'

import { useImpersonate } from 'hooks'

import FullPageLoader from 'components/FullPageLoader'
import Notify from 'components/Notification'
import DatePicker from 'components/DatePicker/DatePicker'

const TemplateApplyModal = ({ templateId, isOpen, onClose }) => {
  const navigate = useNavigate()
  const today = startOfToday()

  const impersonateData = useImpersonate((state) => state.impersonateData)
  const { impersonateUserId, impersonatingUserName } = impersonateData

  const [loading, setLoading] = useState(false)
  const [startValue, setStartValue] = useState('next_week')

  const [dateRange, setDateRange] = useState({
    startDate: add(startOfWeek(today, { weekStartsOn: 1 }), {
      weeks: 2,
    }),
    endDate: add(endOfWeek(today, { weekStartsOn: 1 }), {
      weeks: 2,
    }),
  })

  const handleApplyPlan = async () => {
    setLoading(true)

    const params = {
      client_id: impersonateUserId,
      when_to_add: startValue,
      templated_schedule_id: templateId,
    }

    if (startValue === 'future_week') {
      params['future_week_start'] = dateToString(dateRange.startDate) //format "yyyy-mm-dd"
    }

    console.log(params, 'params')

    securedApi
      .put(`api/v1/templated_schedules/${templateId}/add_to_client`, params)
      .then(() => {
        Notify({
          content: 'New template has been applied',
          type: 'success',
        })
        navigate('/planner')
      })
      .catch((error) => processApiError(error))
      .finally(() => setLoading(false))
  }

  if (loading) {
    return <FullPageLoader />
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} autoFocus={false} isCentered size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Are you sure?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          This template will be added to {impersonatingUserName}'s schedule.
          <Text fontWeight="bold" marginTop="4" marginBottom="2">
            Add the template
          </Text>
          <RadioGroup onChange={setStartValue} value={startValue}>
            <Stack direction="row" spacing="8">
              <Radio value="this_week" colorScheme="yellow">
                This week
              </Radio>
              <Radio value="next_week" colorScheme="yellow">
                Next week
              </Radio>
              <Radio value="future_week" colorScheme="yellow">
                <Text>Future week</Text>
                <DatePicker
                  selected={new Date(dateRange.startDate)}
                  dateFormat="d/MM/yyyy"
                  onChange={(date) => setDateRange({ ...dateRange, startDate: date })}
                  name="startDate"
                  filterDate={(date) => date.getDay() === 1}
                  calendarStartDay={1}
                  placeholderText="Select a Monday"
                />
              </Radio>
            </Stack>
          </RadioGroup>
          <Box marginY="4">
            <Button colorScheme="brand" width="100%" onClick={() => handleApplyPlan()}>
              Confirm
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default TemplateApplyModal
