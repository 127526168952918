import React, { useEffect, useState } from 'react'
import {
  Badge,
  Box,
  Flex,
  Spacer,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
} from '@chakra-ui/react'

import { shortEnglishHumanizer } from 'helpers/duration'

import securedApi from 'backend/axios'

import LoadingSpinner from 'components/LoadingSpinner'
import CategoryStrip from 'components//Workouts/CategoryStrip'

import Details from 'domain/Schedule/ActivityComponents/Details'
import OutdoorToggle from 'domain/Schedule/ActivityComponents/OutdoorToggle'

import WidgetLogbooks from 'domain/Widgets/Logbook/ActivityComponents/WidgetLogbooks'
import WidgetMeasures from 'domain/Widgets/Measure/ActivityComponents/WidgetMeasures'
import WidgetSelects from 'domain/Widgets/Select/ActivityComponents/WidgetSelects'
import WidgetSliders from 'domain/Widgets/Slider/ActivityComponents/WidgetSliders'

import TemplateActivityModalMenu from 'domain/Coaching/Templates/Components/TemplateActivityModalMenu'

import { dayMapper } from '../helpers'

const ActivityModal = ({ id, isOpen, onClose, finalFocusRef, readOnly = false }) => {
  const [loading, setLoading] = useState(true)
  const [currentActivity, setCurrentActivity] = useState()
  const [duration, setDuration] = useState()
  const [currentActivityId, setCurrentActivityId] = useState()
  const [outdoor, setOutdoor] = useState(false)

  // Widgets
  const [logbooks, setLogbooks] = useState([])
  const [measures, setMeasures] = useState([])
  const [selects, setSelects] = useState([])
  const [sliders, setSliders] = useState([])

  useEffect(() => {
    setLoading(true)
    securedApi
      .get(`/api/v1/templated_scheduled_activities/${id}`)
      .then(({ data }) => {
        setCurrentActivity(data)
        setCurrentActivityId(data.id)
        setDuration(data.actual_duration || data.duration)
        setOutdoor(data.actual_outdoor || data.outdoor || false)

        // Widgets
        setLogbooks(data.widgets?.logbooks)
        setMeasures(data.widgets?.measures)
        setSelects(data.widgets?.selects)
        setSliders(data.widgets?.sliders)
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [id])

  const renderContent = () => {
    if (loading) {
      return <LoadingSpinner />
    }

    const {
      id: activity_id,
      templated_activity_id,
      activity_name,
      category_name,
      category_hex_colour,
      activity_description,
      activity_details,
    } = currentActivity

    return (
      <>
        <ModalHeader pb="0">
          <Box display="flex" alignItems="center">
            <Box>{activity_name}</Box>
            <Spacer />
            <Box>
              <Text fontSize="sm">
                {dayMapper[currentActivity.day]} - Week {currentActivity.week}
              </Text>
            </Box>
            <Box mr="-4">
              <TemplateActivityModalMenu
                activityId={activity_id}
                templatedActivityId={templated_activity_id}
                onRemove={() => onClose()}
              />
            </Box>
          </Box>
        </ModalHeader>

        <ModalBody color="gray.600" pb="6">
          <Box display="flex">
            <Box flexGrow="1">
              <CategoryStrip name={category_name} colour={category_hex_colour} inline={true} />

              <Box mt="2">
                <Text fontSize="xs" fontWeight="semibold" color="black">
                  Duration: {duration > 0 ? shortEnglishHumanizer(duration) : '-'}
                </Text>
              </Box>

              <Text fontSize="sm">{activity_description}</Text>
              <Details activityDetails={activity_details} />

              <Box>
                <WidgetSliders
                  scheduledActivityId={currentActivityId}
                  onSetSliders={setSliders}
                  {...{ sliders, readOnly }}
                />
                <WidgetSelects
                  scheduledActivityId={currentActivityId}
                  onSetSelects={setSelects}
                  {...{ selects, readOnly }}
                />
                <WidgetMeasures
                  scheduledActivityId={currentActivityId}
                  onSetMeasures={setMeasures}
                  highlightMeasureId={null} //disable highlight
                  {...{ measures, readOnly }}
                />
                <WidgetLogbooks
                  scheduledActivityId={currentActivityId}
                  onSetLogbooks={setLogbooks}
                  {...{ logbooks, readOnly }}
                />
              </Box>

              <Flex marginTop={4}>
                <OutdoorToggle onSetOutdoor={setOutdoor} {...{ outdoor }} {...{ readOnly }} />
                <Spacer />
              </Flex>
            </Box>
          </Box>
          <Flex>
            <Spacer />
            <Badge>Read only</Badge>
          </Flex>
        </ModalBody>
      </>
    )
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="2xl"
      finalFocusRef={finalFocusRef}
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>{renderContent()}</ModalContent>
    </Modal>
  )
}

export default ActivityModal
